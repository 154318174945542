import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import isEqual from "lodash-es/isEqual";

import {
  applyActionToSite,
  SiteAction,
  siteActions,
  SiteItem,
} from "@/api/interfaces/SitesInterfaces";
import {
  getContactsForFilter,
  getSitesForFilter,
  getSitesSearch,
} from "@/api/SitesApi";
import { FilterObject } from "@/components/DropdownFilter/DropdownFilter";
import { SortingDirection, SortingItem } from "@/interfaces/SortingItem";
import { useStore } from "@/store/store";

import SearchInput from "@/components/SearchInput/SearchInput.vue";
import TableHeaderComponent from "@/components/TableHeaderComponent/TableHeaderComponent.vue";

import NavdraverDescriptionIcon from "@/assets/icons/cards/NavdraverDescription.vue";
import {
  getContactsFilterOptions,
  getCountriesFilterOptions,
  getSitesFilterItem,
} from "@/utils/filtersUtils";
import { getCountriesForFilter } from "@/api/CountriesApi";
import { showError, showErrorOptional } from "@/utils/notificationUtils";

export default defineComponent({
  name: "SitesList",
  props: {},
  components: { SearchInput, TableHeaderComponent, NavdraverDescriptionIcon },
  setup(props) {
    const router = useRouter();

    const fieldNames = {
      siteName: "name",
      siteId: "id",
      country: "country_code",
      contactPerson: "contact_person_name",
      number_of_studies: "number_of_studies",
      number_of_assessments: "number_of_assessments",
    };

    const list = ref<{ data: SiteItem[]; selected: SiteItem[] }>({
      data: [],
      selected: [],
    });

    const state = reactive({
      search: "",
      filtersData: {
        sites: {
          items: [],
          values: [],
        } as FilterObject,
        countries: {
          items: [],
          values: [],
        } as FilterObject,
        contacts: {
          items: [],
          values: [],
        } as FilterObject,
      },
      paging: {
        page: 1,
        pageSize: 30,
        total: 0,
      },
      sorting: null as SortingItem[] | null,
      isLoading: false,
    });

    const updateSites = async (clearPaging = false) => {
      if (clearPaging) {
        state.paging.page = 1;
      }

      state.isLoading = true;
      try {
        const sites = await getSitesSearch({
          paging: {
            page_number: state.paging.page,
            amount_per_page: state.paging.pageSize,
          },
          search_pattern: state.search,
          filters: [
            {
              field: fieldNames.siteId,
              values: state.filtersData.sites.values,
            },
            {
              field: fieldNames.country,
              values: state.filtersData.countries.values,
            },
            {
              field: fieldNames.contactPerson,
              values: state.filtersData.contacts.values,
            },
          ].filter((f) => f.values.length),
          sorting: state.sorting ? state.sorting : undefined,
        });
        state.paging.total = sites.total;
        list.value.data = sites.entities;
        state.isLoading = false;
      } catch (ex) {
        showErrorOptional("Error while updating sites", ex);
        console.log(ex);
      } finally {
        state.isLoading = false;
      }
    };

    watch(
      () => ({
        sites: state.filtersData.sites.values,
        countries: state.filtersData.countries.values,
        contacts: state.filtersData.contacts.values,
      }),
      (newValue: any, oldValue: any) => {
        if (!isEqual(newValue, oldValue)) {
          updateSites(true);
        }
      }
    );

    onBeforeMount(() => {
      getSitesForFilter().then((sites) => {
        state.filtersData.sites.items = getSitesFilterItem(sites);
      });
      getCountriesForFilter().then((countries) => {
        state.filtersData.countries.items = [
          { header: "", options: getCountriesFilterOptions(countries) },
        ];
      });
      getContactsForFilter().then((contacts) => {
        state.filtersData.contacts.items = getContactsFilterOptions(contacts);
      });
      updateSites();
    });

    const handleAction = (action: SiteAction, site: SiteItem) => {
      applyActionToSite(action, site);
    };

    const operations = siteActions;

    return {
      state,
      list,
      operations,
      fieldNames,
      getSortingValueForField: computed(() => (field: string) => {
        if (state.sorting) {
          const sorting = state.sorting[0];
          return sorting.field === field ? sorting.direction : null;
        }
        return null;
      }),
      addSite: () => {
        router.push(`/sites/add-site`);
      },
      viewSite: (site: SiteItem) => {
        router.push(`/sites/view-site/${site.id}`);
      },
      pageChange: (page: number) => {
        state.paging.page = page;
        updateSites();
      },
      searchChanged() {
        updateSites(true);
      },
      sortingChange(field: string, direction: SortingDirection | null) {
        if (direction === null) {
          state.sorting = null;
        } else {
          state.sorting = [{ field, direction }];
        }
        updateSites(true);
      },
      selectionChange(val: SiteItem[]) {
        list.value.selected = val;
      },
      handleAction,
    };
  },
});
