<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <defs>
      <path
        id="e5xqvxig1a"
        d="M2.667 9.333H8v1.334H2.667V9.333zm0-2.666H8V8H2.667V6.667zm4-6.667H1.333C.6 0 0 .6 0 1.333V12c0 .733.593 1.333 1.327 1.333h8.006c.734 0 1.334-.6 1.334-1.333V4l-4-4zm2.666 12h-8V1.333H6v3.334h3.333V12z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <path
              d="M0 0H16V16H0z"
              transform="translate(-1273 -164) translate(1257 152) translate(16 12)"
            />
            <path
              d="M2 2L14 2 14 14 2 14z"
              transform="translate(-1273 -164) translate(1257 152) translate(16 12)"
            />
            <g
              transform="translate(-1273 -164) translate(1257 152) translate(16 12) translate(2.667 1.333)"
            >
              <mask id="dw1iwv45fb" fill="#fff">
                <use xlink:href="#e5xqvxig1a" />
              </mask>
              <use fill="#000" fill-rule="nonzero" xlink:href="#e5xqvxig1a" />
              <g fill="#CFD8DC" mask="url(#dw1iwv45fb)">
                <path d="M0 0H16V16H0z" transform="translate(-2.667 -1.333)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
