import { ActionItem } from "@/interfaces/ActionItem";
import { Address } from "@/interfaces/Address";
import { UserProfile } from "./UserInterfaces";
import router from "@/router";
import { sendSiteAction } from "../SitesApi";

export interface SiteProfile extends Address {
  id: string;
  name: string;
  data_storage_id: number;
  country_code: string;
  comment: string;
  web_site: string;
  // service vars
  country_name: string;
  data_storage_name: string;
}

export interface SiteItem {
  id: string;
  name: string;
  country_name: string;
  address: string;
  contact_person_name: string;
  number_of_assessments: number;
  number_of_studies: number;
}

export interface SiteItemForFilter {
  id: string;
  name: string;
  country_name: string;
}

export interface ContactItemForFilter {
  contact_id: string;
  contact_name: string;
  country_name: string;
}
// export interface SiteContact {
//   user_id: string;
//   fist_name: string;
//   last_name: string;
//   title: string;
//   email: string;
//   phone: string;
// }

export enum SiteAction {
  View = "View",
  Edit = "Edit",
  // AssignToStudy = "AssignToStudy",
  // Delete = "Delete",
}

export const siteActions: { [key in SiteAction]: ActionItem } = {
  [SiteAction.View]: { title: "View site details", action: SiteAction.View },
  // [SiteAction.AssignToStudy]: {
  //   title: "Assign to study",
  //   action: SiteAction.AssignToStudy,
  // },
  [SiteAction.Edit]: {
    title: "Edit site details",
    action: SiteAction.Edit,
  },
  // [SiteAction.Delete]: {
  //   title: "Delete",
  //   action: SiteAction.Delete,
  // },
};

export async function applyActionToSite(
  action: SiteAction,
  site: SiteProfile | SiteItem
) {
  const id = site.id;

  switch (action) {
    case SiteAction.View:
      router.push(`/sites/view-site/${id}`);
      break;
    case SiteAction.Edit:
      router.push(`/sites/edit-site/${id}`);
      break;
    default:
      {
        await sendSiteAction(site, action);
      }
      break;
  }
}
